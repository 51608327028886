export default [
    {
        path: "/:uni_acid/addons",
        name:"addons",
        component: ()=>import("@/view/addons/index"),
        meta:{
            title:"应用管理"
        }
    },

]