export default [
    {
        path:"/manager",
        component:()=>import("@/view/manager/index"),
        redirect:"/manager/uni",
        name:"manager",
        meta: {
            title:"平台管理"
        },
        children:[
            {
                path:"uni",
                name:"managerUni",
                component:()=>import("@/view/manager/uni"),
                meta:{
                    title:"平台管理"
                }
            },
            {
                path:"user",
                name:"managerUser",
                component:()=>import("@/view/manager/user"),
                meta:{
                    title:"账号管理"
                }
            },
            {
                path:"attachment",
                name:"managerAttachment",
                component:()=>import("@/view/manager/attachment"),
                meta:{
                    title:"附件管理"
                }
            },
            {
                path:"site",
                name:"managerSite",
                component:()=>import("@/view/manager/site"),
                meta:{
                    title:"站点设置"
                }
            },
            {
                path:"sys",
                name:"managerSys",
                component:()=>import("@/view/manager/sys"),
                meta:{
                    title:"系统信息"
                }
            },
        ]
    }
]