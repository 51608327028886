export default [
    {
        path: "/:uni_acid/ivideo",
        redirect: {name:"ivideo-list"},
        name: "ivideo",
        component: ()=>import("@/view/plugin/video/index"),
        meta:{
            title:"视频管理"
        },
        children:[
            {
                path:"list",
                name:"ivideo-list",
                component:()=>import("@/view/plugin/video/list"),
                meta:{
                    title:"视频列表"
                }
            },
            {
                path:"edit",
                name:"ivideo-edit",
                component:()=>import("@/view/plugin/video/edit"),
                meta:{
                    title:"编辑视频"
                }
            },
        ]
    }
]