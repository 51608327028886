import { defaultPostFunc} from "@/api/index";

const system = {};
const base = "/system";

system.clearCashierQrcodeCache =  (params)=>defaultPostFunc(base + "/clear-cashier-qrcode-cache",params);

system.homeBannerEdit =  (params)=>defaultPostFunc(base + "/home-banner-edit",params);
system.homeBannerAll =  (params)=>defaultPostFunc(base + "/home-banner-all",params);
system.homeBannerDel =  (params)=>defaultPostFunc(base + "/home-banner-del",params);

system.homeTabbarEdit =  (params)=>defaultPostFunc(base + "/home-tabbar-edit",params);
system.homeTabbarAll =  (params)=>defaultPostFunc(base + "/home-tabbar-all",params);
system.homeTabbarDel =  (params)=>defaultPostFunc(base + "/home-tabbar-del",params);

system.menuInfo =  (params)=>defaultPostFunc(base + "/menu-info",params);


system.qiniuTest =  (params)=>defaultPostFunc(base + "/qiniu/test",params);
system.qiniuAllLocal2Remote =  (params)=>defaultPostFunc(base + "/qiniu/all-local2remote",params);


export default system;