export default [
    {
        path: "/:uni_acid/channel",
        name:"channel",
        component: ()=>import("@/view/channel/index"),
        meta:{
            title:"渠道管理"
        },
        redirect: {name:"channelApp"},
        children:[
            {
                path:"app",
                name:"channelApp",
                component:()=>import("@/view/channel/app/index"),
                meta:{
                    title:"微信公众号"
                }
            },
            {
                path:"wxapp",
                name:"channelWxapp",
                component:()=>import("@/view/channel/wxapp/index"),
                meta:{
                    title:"微信小程序"
                }
            },
            {
                path:"wxpay",
                name:"channelWxpay",
                component:()=>import("@/view/channel/wxpay/index"),
                meta:{
                    title:"微信支付"
                }
            },
        ],
    },

]