export default [
    {
        path: "/:uni_acid/plugin/agent",
        redirect: {name:"agentList"},
        name: "agent",
        component: ()=>import("@/view/plugin/agent/index"),
        meta:{
            title:"代理管理"
        },
        children:[
            {
                path:"list",
                name:"agentList",
                component:()=>import("@/view/plugin/agent/list"),
                meta:{
                    title:"代理列表"
                }
            },
            {
                path:"active",
                name:"agentActive",
                component:()=>import("@/view/plugin/agent/active"),
                meta:{
                    title:"待审核活动"
                }
            },
            {
                path:"balance-change",
                name:"agentBalanceChange",
                component:()=>import("@/view/plugin/agent/balanceChange"),
                meta:{
                    title:"代理余额明细"
                }
            },
            {
                path:"cash",
                name:"cashList",
                component:()=>import("@/view/plugin/agent/cash"),
                meta:{
                    title:"提现打款"
                }
            }
        ]
    },
    {
        path:"/:uni_acid/plugin/u-agent/finance",
        name: "agentFinance",
        redirect: {name:"agentFinanceInfo"},
        component:()=>import("@/view/plugin/agent/specific/index"),
        meta:{
            title:"财务"
        },
        children:[
            {
                path:"info",
                name:"agentFinanceInfo",
                component:()=>import("@/view/plugin/agent/specific/info"),
                meta:{
                    title:"账户信息"
                }
            },
            {
                path:"cash",
                name:"agentFinanceCash",
                component:()=>import("@/view/plugin/agent/specific/cash"),
                meta:{
                    title:"提现记录"
                }
            },
        ]
    }
]