import VueRouter from 'vue-router';
import store from "@/store";
import {Message} from "element-ui";
import sys from "@/router/sys";
import active from "@/router/active";
import industry from "@/router/industry";
import merchant from "@/router/merchant";
import notice from "@/router/notice";
import user from "@/router/user";
import system from "@/router/system";
import log from "@/router/log";
import channel from "@/router/channel";
import addons from "@/router/addons";
import account from "@/router/account";
import manager from "@/router/manager";
import tpl from "@/router/tpl";
import role from "@/router/role";
import plugin from "@/router/plugin"

const routes = [
    ...sys,
    ...account,
    ...manager,
    {
        path: "/:uni_acid/home",
        component: () => import("@/view/home/index"),
        name: "home",
        meta: {
            title: "概览"
        }
    },
    ...active,
    ...tpl,
    ...industry,
    ...merchant,
    ...notice,
    ...user,
    ...system,
    ...log,
    ...channel,
    ...addons,
    ...role,
    ...plugin,
]
const router = new VueRouter({
    history: "hash",
    routes: routes,
});
router.beforeEach(async (to, from, next) => {
    if (to.meta.title) {
        document.title = to.meta.title;
    }
    if (!to.name || !["login", "register", "forget"].includes(to.name)) {
        if (!await store.dispatch("user/token")) {
            Message.error("请先登陆");
            next({
                path: "/",
                query: {
                    redirect: to.fullPath
                }
            })
            return;
        }
    }
    if (!["login", "register", "forget"].includes(to.name)) {
        let userInfo = await store.dispatch("user/loadInfo");
        if (to.path !== "/account/info" && userInfo.status !== 2) {
            Message({message: store.state.user.info.status_str, type: "error"})
            if (from.path === "/account/info") {
                next(false);
                return;
            }
            next({
                path: "/account/info",
            })
            return
        }
    }
    next();
})
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
    if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
    return originalPush.call(this, location).catch(err => err)
}

router.toLoginPage = () => {
    router.push("/");
}
router.toHomePage = () => {
    router.push("/home");
}
router.toAccountPage = () => {
    router.push("/account/info");
}
router.toManagerPage = () => {
    router.push("/manager/uni")
}
export default router


