export default [
    {
        path:"/account",
        component:()=>import("@/view/account/index"),
        redirect:"/account/info",
        name:"account",
        meta: {
            title:"个人账号"
        },
        children:[
            {
                path:"info",
                name:"accountInfo",
                component:()=>import("@/view/account/info"),
                meta:{
                    title:"个人账号"
                }
            }
        ]
    }
]