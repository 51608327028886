
import {defaultPostFunc} from "@/api/index";

const merchant = {};
const base = "/merchant";
merchant.add = (params)=>defaultPostFunc(base + "/add",params);
merchant.one = (params)=>defaultPostFunc(base + "/one",params);
merchant.update = (params)=>defaultPostFunc(base + "/update",params);
merchant.del = (params)=>defaultPostFunc(base + "/del",params);
merchant.search = (params)=>defaultPostFunc(base + "/search",params);


export default merchant;


const coupon = {};
const couponBase = "/coupon";
coupon.one = (params)=>defaultPostFunc(couponBase + "/one",params);
coupon.add = (params)=>defaultPostFunc(couponBase + "/add",params);
coupon.update = (params)=>defaultPostFunc(couponBase + "/update",params);
coupon.del = (params)=>defaultPostFunc(couponBase + "/del",params);
coupon.search = (params)=>defaultPostFunc(couponBase + "/search",params);
coupon.page = (params)=>defaultPostFunc(couponBase + "/page",params);


const store = {};
const storeBase = "/store";
store.one = (params)=>defaultPostFunc(storeBase + "/one",params);
store.add = (params)=>defaultPostFunc(storeBase + "/add",params);
store.update = (params)=>defaultPostFunc(storeBase + "/update",params);
store.del = (params)=>defaultPostFunc(storeBase + "/del",params);
store.search = (params)=>defaultPostFunc(storeBase + "/search",params);
store.page = (params)=>defaultPostFunc(storeBase + "/page",params);

const clerk = {};
const clerkBase = "/clerk";
clerk.all = (params)=>defaultPostFunc(clerkBase + "/all",params);
clerk.del = (params)=>defaultPostFunc(clerkBase + "/del",params);
clerk.add = (params)=>defaultPostFunc(clerkBase + "/add",params);

const ivideo = {};
const videoBase = "/video";
ivideo.all = (params)=>defaultPostFunc(videoBase + "/all",params);
ivideo.del = (params)=>defaultPostFunc(videoBase + "/del",params);
ivideo.add = (params)=>defaultPostFunc(videoBase + "/add",params);
ivideo.update = (params)=>defaultPostFunc(videoBase + "/update",params);


export {
    coupon,
    store,
    clerk,
    ivideo,
}