import {defaultPostFunc} from "@/api/index";

const active = {};
const base = "/active";

active.entry = (params)=>defaultPostFunc(base + "/entry",params);
active.qrcodeGenerate = (params)=>defaultPostFunc(base + "/qrcode-generate",params);

active.merchantList = (params)=>defaultPostFunc(base + "/merchant-list",params);

active.homeDesc = (params)=>defaultPostFunc(base + "/home-desc",params);
active.homeMember = (params)=>defaultPostFunc(base + "/home-member",params);
active.homeJoin = (params)=>defaultPostFunc(base + "/home-join",params);
active.homeCash = (params)=>defaultPostFunc(base + "/home-cash",params);
active.homeCoupon = (params)=>defaultPostFunc(base + "/home-coupon",params);

active.one = (params)=>defaultPostFunc(base + "/one",params);
active.add = (params)=>defaultPostFunc(base + "/add",params);
active.update = (params)=>defaultPostFunc(base + "/update",params);
active.search = (params)=>defaultPostFunc(base + "/search",params);
active.del = (params)=>defaultPostFunc(base + "/del",params);
active.designFetch = (params)=>defaultPostFunc(base + "/design/fetch",params);
active.designUpdate = (params)=>defaultPostFunc(base + "/design/update",params);
active.couponPage = (params)=>defaultPostFunc(base + "/coupon/page",params);
active.couponAdd = (params)=>defaultPostFunc(base + "/coupon/add",params);
active.couponDel = (params)=>defaultPostFunc(base + "/coupon/del",params);

active.designTplDel = (params)=>defaultPostFunc(base + "/designtpl/del",params);
active.designTplAdd = (params)=>defaultPostFunc(base + "/designtpl/add",params);
active.designTplUserOne = (params)=>defaultPostFunc(base + "/designtpl/userone",params);
active.designTplSysOne = (params)=>defaultPostFunc(base + "/designtpl/sysone",params);
active.designTplUserList = (params)=>defaultPostFunc(base + "/designtpl/userlist",params);
active.designTplSysList = (params)=>defaultPostFunc(base + "/designtpl/syslist",params);

active.formAdd = (params)=>defaultPostFunc(base + "/form/add",params);
active.formUpdate = (params)=>defaultPostFunc(base + "/form/update",params);
active.formAll = (params)=>defaultPostFunc(base + "/form/all",params);
active.formDel = (params)=>defaultPostFunc(base + "/form/del",params);

active.posterAdd = (params)=>defaultPostFunc(base + "/poster/add",params);
active.posterUpdate = (params)=>defaultPostFunc(base + "/poster/update",params);
active.posterAll = (params)=>defaultPostFunc(base + "/poster/all",params);
active.posterDel = (params)=>defaultPostFunc(base + "/poster/del",params);
active.posterClearCache = (params)=>defaultPostFunc(base + "/poster/clear",params);

active.signCodeDel = (params)=>defaultPostFunc(base + "/signcode/del",params);
active.signCodeSearch = (params)=>defaultPostFunc(base + "/signcode/search",params);
active.signCodeMake = (params)=>defaultPostFunc(base + "/signcode/make",params);
active.signCodeImport = (params)=>defaultPostFunc(base + "/signcode/import",params);
active.signCodeExport = (params)=>defaultPostFunc(base + "/signcode/export",params);


active.turnTableAll = (params)=>defaultPostFunc(base + "/turntable/all",params);
active.turntableEdit = (params)=>defaultPostFunc(base + "/turntable/edit",params);
active.turntableDel = (params)=>defaultPostFunc(base + "/turntable/del",params);

active.drawGirdAll = (params)=>defaultPostFunc(base + "/draw-gird/all",params);
active.drawGirdEdit = (params)=>defaultPostFunc(base + "/draw-gird/edit",params);
active.drawGirdDel = (params)=>defaultPostFunc(base + "/draw-gird/del",params);

active.drawBoxAll = (params)=>defaultPostFunc(base + "/draw-box/all",params);
active.drawBoxEdit = (params)=>defaultPostFunc(base + "/draw-box/edit",params);
active.drawBoxDel = (params)=>defaultPostFunc(base + "/draw-box/del",params);

active.shareGiftAll = (params)=>defaultPostFunc(base + "/share-gift/all",params);
active.shareGiftEdit = (params)=>defaultPostFunc(base + "/share-gift/edit",params);
active.shareGiftDel = (params)=>defaultPostFunc(base + "/share-gift/del",params);

active.managerAll = (params)=>defaultPostFunc(base + "/manager/all",params);
active.managerAdd = (params)=>defaultPostFunc(base + "/manager/add",params);
active.managerDel = (params)=>defaultPostFunc(base + "/manager/del",params);


active.recordOrder = (params)=>defaultPostFunc(base + "/record-order",params);
active.recordOrderDesc = (params)=>defaultPostFunc(base + "/record-order-desc",params);
active.recordOrderDel = (params)=>defaultPostFunc(base + "/record-order-del",params);
active.recordOrderExport = (params)=>defaultPostFunc(base + "/record-order-export",params);
active.recordCoupon = (params)=>defaultPostFunc(base + "/record-coupon",params);
active.recordCouponExport = (params)=>defaultPostFunc(base + "/record-coupon-export",params);
active.recordCouponCashier = (params)=>defaultPostFunc(base + "/record-coupon-cashier",params);
active.recordCouponCashierExport = (params)=>defaultPostFunc(base + "/record-coupon-cashier-export",params);
active.recordReward = (params)=>defaultPostFunc(base + "/record-reward",params);
active.recordRewardExport = (params)=>defaultPostFunc(base + "/record-reward-export",params);
active.recordRewardDesc = (params)=>defaultPostFunc(base + "/record-reward-desc",params);
active.recordAwardHands= (params)=>defaultPostFunc(base + "/record-award-hands",params);
active.recordAwardHandsExport= (params)=>defaultPostFunc(base + "/record-award-hands-export",params);
active.recordAwardHandsPay= (params)=>defaultPostFunc(base + "/record-award-hands-pay",params);
active.recordAwardCheck= (params)=>defaultPostFunc(base + "/record-award-check",params);
active.recordAwardHandsMergeSee= (params)=>defaultPostFunc(base + "/record-award-hands-merge-see",params);
active.recordAwardHandsMergePay= (params)=>defaultPostFunc(base + "/record-award-hands-merge-pay",params);
active.recordVisit= (params)=>defaultPostFunc(base + "/record-visit",params);
active.recordVisitExport= (params)=>defaultPostFunc(base + "/record-visit-export",params);
active.recordMerchantShare= (params)=>defaultPostFunc(base + "/record-merchant-share",params);
active.recordMerchantShareExport= (params)=>defaultPostFunc(base + "/record-merchant-share-export",params);


export default active;