import {defaultPostFunc} from "@/api";

const shop = {};
const base = "/shop";

shop.cateSearch = (params) => defaultPostFunc(base + "/cate-search", params);
shop.cateEdit = (params) => defaultPostFunc(base + "/cate-edit", params);
shop.cateDel = (params) => defaultPostFunc(base + "/cate-del", params);

shop.goodsSearch = (params) => defaultPostFunc(base + "/goods-search", params);
shop.goodsOne = (params) => defaultPostFunc(base + "/goods-one", params);
shop.goodsEdit = (params) => defaultPostFunc(base + "/goods-edit", params);
shop.goodsDel = (params) => defaultPostFunc(base + "/goods-del", params);
shop.goodsPosterClearCache = (params) => defaultPostFunc(base + "/goods-poster-clear-cache", params);
shop.goodsFormEdit = (params) => defaultPostFunc(base + "/goods-form-edit", params);
shop.goodsFormAll = (params) => defaultPostFunc(base + "/goods-form-all", params);
shop.goodsFormDel = (params) => defaultPostFunc(base + "/goods-form-del", params);
shop.goodsCDKEYSearch = (params) => defaultPostFunc(base + "/goods-cdkey-search", params);
shop.goodsCDKEYMake = (params) => defaultPostFunc(base + "/goods-cdkey-make", params);
shop.goodsCDKEYImport = (params) => defaultPostFunc(base + "/goods-cdkey-import", params);
shop.goodsCDKEYDel = (params) => defaultPostFunc(base + "/goods-cdkey-del", params);

shop.designSearch = (params) => defaultPostFunc(base + "/design-search", params);
shop.designOne = (params) => defaultPostFunc(base + "/design-one", params);
shop.designEdit = (params) => defaultPostFunc(base + "/design-edit", params);
shop.designDel = (params) => defaultPostFunc(base + "/design-del", params);
shop.designQrcodeGet = (params) => defaultPostFunc(base + "/design-qrcode-get", params);
shop.designPosterClearCache = (params) => defaultPostFunc(base + "/design-poster-clear-cache", params);
shop.designGoodsAll = (params) => defaultPostFunc(base + "/design-goods-all", params);
shop.designGoodsDose = (params) => defaultPostFunc(base + "/design-goods-dose", params);
shop.designSignCodeSearch = (params) => defaultPostFunc(base + "/design-sign-code-search", params);
shop.designSignCodeImport = (params) => defaultPostFunc(base + "/design-sign-code-import", params);
shop.designSignCodeMake = (params) => defaultPostFunc(base + "/design-sign-code-make", params);
shop.designSignCodeDel = (params) => defaultPostFunc(base + "/design-sign-code-del", params);
shop.designMerchantList = (params) => defaultPostFunc(base + "/design-merchant-list", params);

shop.order = (params) => defaultPostFunc(base + "/order", params);
shop.award = (params) => defaultPostFunc(base + "/award", params);
shop.awardPay = (params) => defaultPostFunc(base + "/award-pay", params);

shop.cash = (params) => defaultPostFunc(base + "/cash", params);
shop.cashPay = (params) => defaultPostFunc(base + "/cash-pay", params);
shop.cashFail = (params) => defaultPostFunc(base + "/cash-fail", params);

shop.settingBasic = (params) => defaultPostFunc(base + "/setting-basic", params);
shop.settingBasicEdit = (params) => defaultPostFunc(base + "/setting-basic-edit", params);

export default shop;