import Vue from "vue";

import config from "@/api/config";
import account from "@/api/account";
import industry from "@/api/industry";
import attach from "@/api/attach";
import setting from "@/api/setting";
import merchant,{coupon,store,clerk,ivideo} from "@/api/merchant";
import active from "@/api/active";
import manager from "@/api/manager";
import channel from "@/api/channel";
import member from "@/api/member";
import order from "@/api/order";
import log from "@/api/log";
import router from "@/router";
import system from "@/api/system";
import addons from "@/api/addons";
import role from "@/api/role";
import plugin from "@/api/plugin"


export function uriFullUniacid(){
    let uni_acid = router.app.$route.params.uni_acid;
    uni_acid ? uni_acid = "/" + uni_acid : uni_acid = "/0"
    return uni_acid;
}
export function defaultGetFunc(uri){
    let uni_acid = uriFullUniacid();
    return Vue.axios.get(config.api_base + uni_acid + uri)
}
export function defaultPostFunc(uri,param={}){
    let uni_acid = uriFullUniacid();
    return Vue.axios.post(config.api_base + uni_acid + uri,param)
}

export default {
    account,
    manager,
    industry,
    attach,
    setting,
    merchant,
    store,
    coupon,
    clerk,
    ivideo,
    active,
    channel,
    member,
    order,
    log,
    system,
    addons,
    role,
    plugin
}