import {defaultPostFunc} from "@/api";

const agent = {};
const base = "/agent";

agent.list = (params) => defaultPostFunc(base + "/list", params);
agent.add = (params) => defaultPostFunc(base + "/add", params);
agent.del = (params) => defaultPostFunc(base + "/del", params);
agent.editInfo = (params) => defaultPostFunc(base + "/edit-info", params);
agent.editPassword = (params) => defaultPostFunc(base + "/edit-password", params);

agent.adminBalanceChange = (params) => defaultPostFunc(base + "/admin-balance-change", params);
agent.adminCashList = (params) => defaultPostFunc(base + "/admin-cash-list", params);
agent.adminCashWxPay = (params) => defaultPostFunc(base + "/admin-cash-wx-pay", params);
agent.adminCashChangePay = (params) => defaultPostFunc(base + "/admin-cash-change-pay", params);
agent.UBalanceChange = (params) => defaultPostFunc(base + "/u-balance-change", params);
agent.UWantCash = (params) => defaultPostFunc(base + "/u-want-cash", params);
agent.UCashList = (params) => defaultPostFunc(base + "/u-cash-list", params);

export default agent;