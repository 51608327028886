
import {defaultPostFunc} from "@/api/index";

const attach = {};
const base =  "/attach"

attach.cert = {
    cert: base + "/pay-cert",
    key: base + "/pay-key"
}

attach.js = base + "/wx-official-js"

attach.img = {
    action: base + "/image",
    del: (params)=>defaultPostFunc(base + "/image/del",params),
    page: (params)=>defaultPostFunc(base + "/image/page",params)
}
attach.video = {
    action: base + "/video",
    del: (params)=>defaultPostFunc(base + "/video/del",params),
    page: (params)=>defaultPostFunc(base + "/video/page",params)
}
attach.audio = {
    action: base + "/audio",
    del: (params)=>defaultPostFunc(base + "/audio/del",params),
    page: (params)=>defaultPostFunc(base + "/audio/page",params)
}

attach.transcode = {
    video:(params)=>defaultPostFunc(base + "/video-transcode",params)
}

attach.group = {
    del: (params)=>defaultPostFunc(base + "/group/del",params),
    add: (params)=>defaultPostFunc(base + "/group/add",params),
    all: (params)=>defaultPostFunc(base + "/group/all",params),
    move: (params)=>defaultPostFunc(base + "/group/move",params),
}


export default attach;