export default [
    {
        path: "/:uni_acid/plugin/voice-red-packet",
        redirect: {name:"voice-red-packet-list"},
        name: "voice-red-packet",
        component: ()=>import("@/view/plugin/voiceRedPacket/index"),
        meta:{
            title:"语音红包"
        },
        children:[
            {
                path:"list",
                name:"voice-red-packet-list",
                component:()=>import("@/view/plugin/voiceRedPacket/list"),
                meta:{
                    title:"红包列表"
                }
            },
            {
                path:"setting",
                name:"voice-red-packet-setting",
                component:()=>import("@/view/plugin/voiceRedPacket/setting"),
                meta:{
                    title:"基础配置"
                }
            },
            {
                path:"design",
                name:"voice-red-packet-design",
                redirect: {name:"voice-red-packet-design-list"},
                component:()=>import("@/view/plugin/voiceRedPacket/design"),
                meta:{
                    title:"聚合页DIY"
                },
                children:[
                    {
                        path:"list",
                        name:"voice-red-packet-design-list",
                        component:()=>import("@/view/plugin/voiceRedPacket/design/list"),
                        meta:{
                            title:"聚合页列表"
                        },
                    },
                    {
                        path:"edit",
                        name:"voice-red-packet-design-edit",
                        component:()=>import("@/view/plugin/voiceRedPacket/design/edit"),
                        meta:{
                            title:"聚合页编辑"
                        },
                    }
                ],
            }
        ]
    }
]