export default [
    {
        path: "/:uni_acid/system",
        redirect: {name:"systemInfo"},
        name: "system",
        component: ()=>import("@/view/system/index"),
        meta:{
            title:"系统配置"
        },
        children:[
            {
                path:"info",
                name:"systemInfo",
                component:()=>import("@/view/system/info"),
                meta:{
                    title:"系统配置"
                }
            },
            {
                path:"attach",
                name:"systemAttach",
                component:()=>import("@/view/system/attach"),
                meta:{
                    title:"远程附件"
                }
            },
            {
                path:"banner",
                name:"homeBanner",
                component:()=>import("@/view/system/banner"),
                meta:{
                    title:"首页轮播图"
                }
            },
            {
                path:"tabbar",
                name:"homeTabbar",
                component:()=>import("@/view/system/tabbar"),
                meta:{
                    title:"首页轮播图"
                }
            }
        ]
    },

]