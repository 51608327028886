import api from "@/api";
const setting = {
    namespaced:true,
    state:()=>({
        attachment_url:"",
        login_banner:"",
        copyright:"",
        icp:"",
        site_logo:"",
        index_bg_img:"",
        index_title:"",
        uni_attachment_url:{}
    }),
    mutations:{
        set(state,data){
            state.attachment_url = data.attachment_url;
            state.login_banner = data.login_banner;
            state.copyright = data.copyright;
            state.icp = data.icp;
            state.site_logo = data.site_logo;
            state.index_bg_img = data.index_bg_img;
            state.index_title = data.index_title;
            state.uni_attachment_url = data.uni_attachment_url;
        }
    },
    actions:{
        load(context){
            if (!context.state.attachment_url){
                api.setting.global().then(res=>{
                    context.commit("set",res);
                })
            }
            return context.state;
        },
    }
}
export default setting;