import { defaultPostFunc} from "@/api/index";

const member = {};
const base = "/member";
member.search = (params)=>defaultPostFunc(base + "/search",params);
member.del = (params)=>defaultPostFunc(base + "/del",params);
member.black = (params)=>defaultPostFunc(base + "/black",params);
member.one = (params)=>defaultPostFunc(base + "/one",params);


export default member;