export default [
    {
        path: "/:uni_acid/user",
        name:"user",
        component: ()=>import("@/view/user/index"),
        redirect: {name:"userList"},
        meta:{
            title:"用户管理"
        },
        children:[
            {
                path:"index",
                name:"userList",
                component:()=>import("@/view/user/list"),
                meta:{
                    title:"用户列表"
                }
            }
        ],
    },

]