export default [
    {
        path: "/:uni_acid/role",
        redirect: {name:"rolePanel"},
        name: "role",
        component: ()=>import("@/view/role/index"),
        meta:{
            title:"角色"
        },
        children:[
            {
                path:"panel",
                name:"rolePanel",
                component:()=>import("@/view/role/panel"),
                meta:{
                    title:"角色面板"
                }
            }
        ]
    },

]