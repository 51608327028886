import axios from "axios";
import { Message } from 'element-ui';
import router from "@/router";
import store from "@/store";

axios.defaults.baseURL = process.env.NODE_ENV === 'production' ? location.origin : "http://localhost:8089";
axios.defaults.headers.post['Content-Type'] = 'application/json;charset=UTF-8';

axios.interceptors.request.use( async function (config){
    //发送请求前
    config.headers.ytoken = await store.dispatch("user/token")
    return config;
},function (error){
    Message.error("网络通信异常");
    return Promise.reject(error)
})

axios.interceptors.response.use(function (response){
    if(response.data.errno !== 0){
        if(response.data.message)Message.error(response.data.message);
        if(response.data.errno === 2 && response.data.data){
            if(router.currentRoute.path !== response.data.data){
                router.push(response.data.data);
            }
        }else if (response.data.errno === 40019){
            router.toLoginPage();
        }
        return Promise.reject(response.data);
    }
    return response.data.data;
},function (error){
    Message.error("响应异常");
    return Promise.reject(error)
})

export default axios