import {defaultPostFunc} from "@/api";

const groupBuy = {};
const base = "/group-buy";

groupBuy.groupList = (params) => defaultPostFunc(base + "/group-list", params);
groupBuy.groupListExp = (params) => defaultPostFunc(base + "/group-list-exp", params);

groupBuy.headJudgeStatus = (params) => defaultPostFunc(base + "/head-judge-status", params);

export default groupBuy;