export default [
    {
        path: "/:uni_acid/shop",
        redirect: {name:"shop-goods"},
        name: "shop",
        component: ()=>import("@/view/plugin/shop/index"),
        meta:{
            title:"商城模式"
        },
        children:[
            {
                path:"set",
                name:"shop-set",
                component:()=>import("@/view/plugin/shop/setting"),
                meta:{
                    title:"基础配置"
                }
            }
            ,{
                path:"cate",
                name:"shop-cate",
                component:()=>import("@/view/plugin/shop/cate"),
                meta:{
                    title:"商品分类"
                }
            },
            {
                path:"goods",
                name:"shop-goods",
                redirect: {name:"shop-goods-list"},
                component:()=>import("@/view/plugin/shop/goods"),
                meta:{
                    title:"商品管理"
                },
                children:[
                    {
                        path:"list",
                        name:"shop-goods-list",
                        component:()=>import("@/view/plugin/shop/goods/list"),
                        meta:{
                            title:"商品列表"
                        },
                    },
                    {
                        path:"edit",
                        name:"shop-goods-edit",
                        component:()=>import("@/view/plugin/shop/goods/edit"),
                        meta:{
                            title:"商品编辑"
                        },
                    }
                ],
            },
            {
                path:"order",
                name:"shop-order",
                component:()=>import("@/view/plugin/shop/order"),
                meta:{
                    title:"订单列表"
                }
            },
            {
                path:"award",
                name:"shop-award",
                component:()=>import("@/view/plugin/shop/award"),
                meta:{
                    title:"奖励列表"
                }
            },
            {
                path:"cash",
                name:"shop-cash",
                component:()=>import("@/view/plugin/shop/cash"),
                meta:{
                    title:"商家提现"
                }
            },
            {
                path:"design",
                name:"shop-design",
                redirect: {name:"shop-design-list"},
                component:()=>import("@/view/plugin/shop/design"),
                meta:{
                    title:"专题页"
                },
                children:[
                    {
                        path:"list",
                        name:"shop-design-list",
                        component:()=>import("@/view/plugin/shop/design/list"),
                        meta:{
                            title:"专题列表"
                        },
                    },
                    {
                        path:"edit",
                        name:"shop-design-edit",
                        component:()=>import("@/view/plugin/shop/design/edit"),
                        meta:{
                            title:"专题页编辑"
                        },
                    }
                ],
            }
        ]
    }
]