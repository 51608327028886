import store from "@/store";
import Vue from "vue";
import config from "@/api/config";
let account = {}
const base = "/account"
account.register = (params)=>Vue.axios.post(config.api_base + base + "/register",params)
account.login = (data)=>{
    return new Promise((resolve, reject) => {
        Vue.axios.post(config.api_base + base + "/login",data).then(res=>{
            store.commit("user/tokenSet",res.token);
            store.commit("user/infoSet",res.info);
            store.commit("menu/set",res);
            resolve(res);
        }).catch(err=>{
            reject(err);
        })
    })
}
account.info = ()=>Vue.axios.get(config.api_base + base + "/info");
account.resetPassword = (params)=>Vue.axios.post(config.api_base + base + "/resetpassword",params);

account.uniEdit = (params)=>Vue.axios.post(config.api_base + base + "/uni/edit",params);

export default account