<template>
  <div>
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'App',
  components: {}
}
</script>

<style>
@import "assets/css/base.css";
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  color: #2c3e50;
}
*{
  margin: 0;
  padding: 0;
}
.el-table .warning-row {
  background: #f8f8f8;
}

.el-table .success-row {
  background: #FFFFFF;
}
</style>
