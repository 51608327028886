export default [
    {
        path: "/:uni_acid/merchant",
        component: ()=>import("@/view/merchant/index"),
        redirect: {name:"merchantList"},
        name:"merchant",
        meta:{
            title:"商户管理"
        },
        children: [
            {
                path:"clerk",
                name:"merchantClerk",
                component:()=>import("@/view/merchant/clerk"),
                meta:{
                    title:"商家核销员管理"
                }
            },
            {
                path:"store",
                name:"merchantStore",
                component:()=>import("@/view/merchant/store"),
                meta:{
                    title:"商家门店管理"
                }
            },
            {
                path:"coupon",
                name:"merchantCoupon",
                component:()=>import("@/view/merchant/coupon"),
                meta:{
                    title:"商家卡券管理"
                }
            },
            {
                path:"ivideo",
                name:"merchantVideo",
                component:()=>import("@/view/merchant/ivideo"),
                meta:{
                    title:"商家视频管理"
                }
            },
            {
                path:"index",
                name:"merchantList",
                component:()=>import("@/view/merchant/list"),
                meta:{
                    title:"商家列表"
                }
            },
            {
                path:"edit",
                name:"merchantEdit",
                component:()=>import("@/view/merchant/edit"),
                meta:{
                    title:"编辑商家"
                }
            },
        ]
    },
]