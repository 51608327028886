import Vue from 'vue';
import Vuex from "vuex";

Vue.use(Vuex);
import user from "@/store/user";
import setting from "@/store/setting";
import menu from "@/store/menu";
import addons from "@/store/addons";
const store = new Vuex.Store({
    modules:{
        user,
        setting,
        menu,
        addons
    }
})

export default store;