import {defaultPostFunc} from "@/api";

const free = {};
const base = "/free";

free.extendFetch = (params) => defaultPostFunc(base + "/extend-fetch", params);
free.extendUpdate = (params) => defaultPostFunc(base + "/extend-update", params);

free.activeCouponEdit = (params) => defaultPostFunc(base + "/active-coupon-edit", params);

free.merchantList = (params) => defaultPostFunc(base + "/merchant-list", params);
free.merchantRecharge = (params) => defaultPostFunc(base + "/merchant-recharge", params);
free.merchantLog = (params) => defaultPostFunc(base + "/merchant-log", params);

export default free;