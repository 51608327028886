import {defaultPostFunc} from "@/api";

const voiceRedPacket = {};
const base = "/voice-red-packet";

voiceRedPacket.opt = (params) => defaultPostFunc(base + "/opt", params);
voiceRedPacket.optEdit = (params) => defaultPostFunc(base + "/opt-edit", params);

voiceRedPacket.designSearch = (params) => defaultPostFunc(base + "/design-search", params);
voiceRedPacket.designOne = (params) => defaultPostFunc(base + "/design-one", params);
voiceRedPacket.designQrcodeGet = (params) => defaultPostFunc(base + "/design-qrcode-get", params);
voiceRedPacket.designEdit = (params) => defaultPostFunc(base + "/design-edit", params);
voiceRedPacket.designDel = (params) => defaultPostFunc(base + "/design-del", params);
voiceRedPacket.designPosterClear = (params) => defaultPostFunc(base + "/design-poster-clear", params);

voiceRedPacket.list = (params) => defaultPostFunc(base + "/list", params);
voiceRedPacket.backend = (params) => defaultPostFunc(base + "/backend", params);
voiceRedPacket.setCustom = (params) => defaultPostFunc(base + "/set-custom", params);

export default voiceRedPacket;