export default [
    {
        path: "/:uni_acid/tpl",
        component: ()=>import("@/view/tpl/index"),
        redirect: {name:"tplMode"},
        name:"tpl",
        children:[
            {
                path:"mode",
                name:"tplMode",
                component:()=>import("@/view/tpl/mode"),
                meta:{
                    title:"模式选择"
                }
            },
            {
                path: "list/:mode/",
                name: "tplModeList",
                component:()=>import("@/view/tpl/list"),
                meta: {
                    title: "模版列表"
                }
            }
        ]
    },
]