import api from "@/api";

const addons = {
    namespaced: true,
    state: () => ({
        uni_acid: false,
        agent: false,
        video: false,
        classic: false,
        group_buy: false,
        shop:false,
        cdkey:false,
        free:false,
    }),
    mutations: {
        set(state, data) {
            state.uni_acid = data.uni_acid;
            state.agent = data.agent;
            state.video = data.video;
            state.classic = data.classic;
            state.group_buy = data.group_buy;
            state.shop = data.shop;
            state.cdkey = data.cdkey;
            state.free = data.free;
        }
    },
    actions: {
        load(context) {
            return new Promise((resolve => {
                if (!context.state.uni_acid) {
                    api.addons.fetch().then(res => {
                        context.commit("set", res);
                        resolve(context.state)
                    })
                    return;
                }
                resolve(context.state)
            }))
        },
        reload(context) {
            api.addons.fetch().then(res => {
                context.commit("set", res);
            })
        }
    }
}
export default addons;