import api from "@/api";
import router from "@/router";
const user = {
    namespaced:true,
    state:()=>({
        token:"",
        info:{
            founder:0,
            register_type:0,
            register_type_str:"",
            remark:"",
            status:0,
            status_str:"",
            username:"",
            uni_account:{
                id:0,
                name:"",
                logo:"",
                tx_map_key:"",
            },
            agent:{
                id:0,
                title:"",
                account:"",
                award_rate:"",
            }
        }
    }),
    getters:{
        is_agent(state){
          return state.info.agent?.id > 0
        },
        is_founder(state){
            if (state.info.agent?.id > 0)return false
            return state.info.founder === 1;
        },
        show_account(state){
            if (state.info.agent?.id > 0){
                return state.info.agent.title
            }
            return state.info.username
        },
        uni_acid(state){
            if (!state.info.uni_account)return 0;
            return state.info.uni_account.id;
        },
        uni_tx_map_key(state){
            if (!state.info.uni_account)return "";
            return state.info.uni_account.tx_map_key;
        }
    },
    mutations:{
        tokenSet(state,token){
            state.token = token;
            localStorage.setItem("ytoken",token)
        },
        infoSet(state,data){
            state.info = data;
        }
    },
    actions:{
        token(context){
            if (!context.state.token){
                let local = localStorage.getItem("ytoken");
                if (local)context.commit("tokenSet",local);
                return local || "";
            }
            return context.state.token;
        },
        loadInfo(context){
            if(context.state.info.username) {
                return context.state.info;
            }
            return new Promise((resolve, reject) => {
                api.account.info().then(res=>{
                    context.commit("infoSet",res);
                    resolve(res);
                }).catch(err=>reject(err));
            })
        },
        reloadInfo(context){
            return new Promise((resolve, reject) => {
                api.account.info().then(res=>{
                    context.commit("infoSet",res);
                    resolve(res);
                }).catch(err=>reject(err));
            })
        },
        logout(context){
            localStorage.removeItem("ytoken");
            context.commit("tokenSet","");
            context.commit("infoSet",{
                username:"",
                status:0,
                status_str:""
            });
            router.toLoginPage();
        }
    }
}
export default user;