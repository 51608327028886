import { defaultPostFunc} from "@/api/index";

const industry = {};
const base = "/industry";
industry.one = (params)=>defaultPostFunc(base + "/one",params);
industry.add = (params)=>defaultPostFunc(base + "/add",params);
industry.update = (params)=>defaultPostFunc(base + "/update",params);
industry.del = (params)=>defaultPostFunc(base + "/del",params);
industry.search = (params)=>defaultPostFunc(base + "/search",params);
industry.page = (params)=>defaultPostFunc(base + "/page",params);
industry.all = (params)=>defaultPostFunc(base + "/all",params);


export default industry;