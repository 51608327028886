import {defaultPostFunc} from "@/api/index";

const addons = {};
const base = "/addons";

addons.fetch = (params)=>defaultPostFunc(base + "/fetch",params);
addons.basic = (params)=>defaultPostFunc(base + "/basic",params);
addons.added = (params)=>defaultPostFunc(base + "/added",params);
addons.addedChange = (params)=>defaultPostFunc(base + "/added-change",params);

export default addons;