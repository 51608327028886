import Vue from 'vue';
import App from './App.vue';
import './plugins/element.js';

import axios from '@/api/axios';
import VueAxios from 'vue-axios';
Vue.use(VueAxios, axios);
import api from "@/api";
Vue.prototype.$api = api;

import store from "@/store"

import VueRouter from 'vue-router';
Vue.use(VueRouter);
import router from "@/router";

import * as echarts from 'echarts';
Vue.prototype.$echarts = echarts;


import VueAreaLinkage from "area-linkage-vue";
Vue.use(VueAreaLinkage);

import VueClipboard from 'vue-clipboard2';
Vue.use(VueClipboard);

import html2canvas from "html2canvas";
Vue.prototype.$html2canvas = html2canvas;

Vue.config.productionTip = false;

Vue.filter("elIcon",(value)=>{
  return `./${process.env.BASE_URL}icon/${value}.png`;
})

Vue.filter("tomedia",(value)=>{
  if(!value)return '';
  if (value.includes("http"))return value;
  let cur_uni_acid = router?.currentRoute?.params?.uni_acid;
  if (!cur_uni_acid){
    return store.state.setting.attachment_url + value;
  }
  return store.state.setting.uni_attachment_url[cur_uni_acid] + value;
  // console.log(store.getters["setting/attachment_url"],router.currentRoute.path);
  // return store.getters["setting/attachment_url"] + value;
})
Vue.filter("date",(value)=>{
  if (!value)return "";
  let date = new Date(value);
  if(date.getTime() < 0)return "";
  var YY = date.getFullYear() + '-';
  var MM = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
  var DD = (date.getDate() < 10 ? '0' + (date.getDate()) : date.getDate());
  var hh = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':';
  var mm = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':';
  var ss = (date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds());
  return YY + MM + DD +" "+hh + mm + ss;
})

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
store.dispatch("setting/load")
