export default [
    {
        path: "/:uni_acid/active",
        component: ()=>import("@/view/active/index"),
        redirect: {name:"activeList"},
        name:"active",
        children:[
            {
                path:"index",
                name:"activeList",
                component:()=>import("@/view/active/list"),
                meta:{
                    title:"活动管理"
                }
            },
            {
                path:"edit",
                name:"activeEdit",
                component:()=>import("@/view/active/edit"),
                meta:{
                    title:"编辑活动"
                }
            }
        ]
    },
]