import {defaultPostFunc} from "@/api";

const cdkey = {};
const base = "/cdkey";

cdkey.cdkeyCodeDel = (params)=>defaultPostFunc(base + "/cdkey/code-del",params);
cdkey.cdkeyCodeSearch = (params)=>defaultPostFunc(base + "/cdkey/code-search",params);
cdkey.cdkeyCodeMake = (params)=>defaultPostFunc(base + "/cdkey/code-make",params);
cdkey.cdkeyCodeImport = (params)=>defaultPostFunc(base + "/cdkey/code-import",params);
cdkey.cdkeyCodeExport = (params)=>defaultPostFunc(base + "/cdkey/code-export",params);
export default cdkey;