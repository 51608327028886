import Vue from "vue";
import config from "@/api/config";

const manager = {};
const base = "/manager"
manager.bindau = (params)=>Vue.axios.post(config.api_base + base + "/bindau",params);
manager.uniSearch = (params)=>Vue.axios.post(config.api_base + base + "/uni/search",params);
manager.uniSearchAll = (params)=>Vue.axios.post(config.api_base + base + "/uni/searchall",params);
manager.uniEdit = (params)=>Vue.axios.post(config.api_base + base + "/uni/edit",params);
manager.uniDel = (params)=>Vue.axios.post(config.api_base + base + "/uni/del",params);
manager.uniTotal = (params)=>Vue.axios.post(config.api_base + base + "/uni/total",params);

manager.accountSearch = (params)=>Vue.axios.post(config.api_base + base + "/account/search",params);
manager.accountSearchAll = (params)=>Vue.axios.post(config.api_base + base + "/account/searchall",params);
manager.accountResetPassword = (params)=>Vue.axios.post(config.api_base + base + "/account/resetpassword",params);
manager.accountDel = (params)=>Vue.axios.post(config.api_base + base + "/account/del",params);
manager.accountAdd = (params)=>Vue.axios.post(config.api_base + base + "/account/add",params);
manager.accountFail = (params)=>Vue.axios.post(config.api_base + base + "/account/fail",params);


manager.systemFetch = (params)=>Vue.axios.post(config.api_base + base + "/system/fetch",params);
manager.systemUpdate = (params)=>Vue.axios.post(config.api_base + base + "/system/update",params);

manager.systemQiNiuTest = (params)=>Vue.axios.post(config.api_base + base + "/system/qiniu-test",params);
manager.systemQiNiuAllLocal2Remote = (params)=>Vue.axios.post(config.api_base + base + "/system/qiniu-all-local2remote",params);

manager.versionRemote = (params)=>Vue.axios.post(config.api_base + base + "/version/remote",params);
manager.versionUpdate = (params)=>Vue.axios.post(config.api_base + base + "/version/update",params);
manager.upgradeLog = (params)=>Vue.axios.post(config.api_base + base + "/version/upgrade-log",params);

export default manager;