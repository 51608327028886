export default [
    {
        path: "/:uni_acid/log",
        name:"log",
        component: ()=>import("@/view/log/index"),
        redirect: {name:"logList"},
        meta:{
            title:"日志系统"
        },
        children:[
            {
                path:"index",
                name:"logList",
                component:()=>import("@/view/log/list"),
                meta:{
                    title:"系统日志"
                }
            }
        ],
    },
]