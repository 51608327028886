import agent from "@/api/plugin/agent";
import classic from "@/api/plugin/classic";
import groupBuy from "@/api/plugin/groupBuy";
import voiceRedPacket from "@/api/plugin/voiceRedPacket";
import free from "@/api/plugin/free";
import shop from "@/api/plugin/shop";
import ivideo from "@/api/plugin/ivideo";
import cdkey from "@/api/plugin/cdkey";

export default {
    agent,
    classic,
    groupBuy,
    voiceRedPacket,
    free,
    shop,
    ivideo,
    cdkey
}