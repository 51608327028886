import { defaultPostFunc} from "@/api/index";

const channel = {};
const base = "/channel";

channel.payCert =  (params)=>defaultPostFunc(base + "/pay-cert",params);

channel.noticeTplOne =  (params)=>defaultPostFunc(base + "/notice-tpl/one",params);
channel.noticeTplUpdate =  (params)=>defaultPostFunc(base + "/notice-tpl/update",params);

channel.wxappInfo = (params)=>defaultPostFunc(base + "/wxapp/info",params);
channel.wxappEdit = (params)=>defaultPostFunc(base + "/wxapp/edit",params);
channel.wxappEntry = (params)=>defaultPostFunc(base + "/wxapp/entry",params);
channel.wxappUpload = (params)=>defaultPostFunc(base + "/wxapp/upload",params);

channel.wxofficialEntry = (params)=>defaultPostFunc(base + "/wxofficial/entry",params);
channel.wxofficialInfo = (params)=>defaultPostFunc(base + "/wxofficial/info",params);
channel.wxofficialEdit = (params)=>defaultPostFunc(base + "/wxofficial/edit",params);
channel.wxofficialConnectEdit = (params)=>defaultPostFunc(base + "/wxofficial/connectedit",params);
channel.wxofficialMenuEdit = (params)=>defaultPostFunc(base + "/wxofficial/menu/edit",params);
channel.wxofficialMenuInfo = (params)=>defaultPostFunc(base + "/wxofficial/menu/info",params);
channel.wxofficialMenuDel = (params)=>defaultPostFunc(base + "/wxofficial/menu/del",params);

export default channel;