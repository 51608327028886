export default [
    {
        path: "/:uni_acid/notice",
        name:"notice",
        component: ()=>import("@/view/notice/index"),
        meta:{
            title:"消息通知管理"
        }
    },

]