import {defaultPostFunc} from "@/api";

const ivideo = {};
const base = "/ivideo";

ivideo.one = (params) => defaultPostFunc(base + "/one", params);
ivideo.search = (params) => defaultPostFunc(base + "/search", params);
ivideo.edit = (params) => defaultPostFunc(base + "/edit", params);
ivideo.del = (params) => defaultPostFunc(base + "/del", params);

export default ivideo;