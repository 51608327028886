export default [
    {
        path: "/:uni_acid/industry",
        name:"industry",
        component: ()=>import("@/view/industry/index"),
        redirect:{name:"industryList"},
        meta:{
            title:"行业管理"
        },
        children:[
            {
                path:"index",
                name:"industryList",
                component:()=>import("@/view/industry/list"),
                meta:{
                    title:"行业管理"
                }
            }
        ]
    },
]