import { defaultPostFunc} from "@/api/index";

const log = {};
const base = "/log";

log.pay = (params)=>defaultPostFunc(base + "/pay",params);
log.coupon = (params)=>defaultPostFunc(base + "/coupon",params);
log.reward = (params)=>defaultPostFunc(base + "/reward",params);
log.visit = (params)=>defaultPostFunc(base + "/visit",params);
log.cashier = (params)=>defaultPostFunc(base + "/cashier",params);


export default log;